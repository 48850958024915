import {
  Button,
  CardContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { MainContext } from "../../controllers/main"
import EmbedSlice from "../../models/bodySlices/embedSlice"
import ImageSlice from "../../models/bodySlices/imageSlice"
import QuoteSlice from "../../models/bodySlices/quoteSlice"
import TextSlice from "../../models/bodySlices/textSlice"
import BlockEditor from "../blockEditor/blockEditor"
import { BodySliceType } from "../../services/config/enum"
import {
  deepCopy,
  isValidURL,
  stripHtml,
  testYtURL,
} from "../../services/utilities/utility"
import { ChallengesContext } from "../../controllers/challenges"
import Challenge from "../../models/challenge"
import ImagePicker from "../global/imagePicker"
import BlockEditorPrizes from "../blockEditorPrizes/blockEditorPrizes"
import { InfoRounded } from "@mui/icons-material"

const ChallengeTranslationsEdit = ({ itemIndex }: { itemIndex: number }) => {
  const { showPadding } = useContext(MainContext)
  const {
    currentChallenge,
    setCurrentChallenge,
    editMode,
    translationsErrors,
    setTranslationsErrors,
    copyDetailsFromDefault,
    copyBodyFromDefault,
    copyPrizesFromDefault,
  } = useContext(ChallengesContext)

  // current item
  const currentItem = currentChallenge.document.items[itemIndex]

  // errors managment
  const [titleError, setTitleError] = useState<boolean>(false) // error for title
  const [bodyError, setBodyError] = useState<boolean>(false) // error for body
  const [prizesError, setPrizesError] = useState<boolean>(false) // error for prizes

  // update translations errors on errors change
  useEffect(() => {
    if (titleError || bodyError || prizesError) {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = true
    } else {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = false
    }

    setTranslationsErrors([...translationsErrors])
  }, [titleError, bodyError, prizesError])

  // update errors on currentItem change
  useEffect(() => {
    if (currentItem.title.length < 3 || currentItem.title.length > 120) {
      setTitleError(true)
    } else {
      setTitleError(false)
    }

    let errorInBody = false
    if (currentItem.body) {
      currentItem.body.forEach((bodyItem) => {
        if (
          (bodyItem.sliceType === BodySliceType.BodyTextSlice &&
            !stripHtml(bodyItem.text).length) ||
          (bodyItem.sliceType === BodySliceType.BodyQuoteSlice &&
            !bodyItem.quoteText.length) ||
          (bodyItem.sliceType === BodySliceType.BodyImageSlice &&
            (!bodyItem.imgUrl.length || !isValidURL(bodyItem.imgUrl))) ||
          (bodyItem.sliceType === BodySliceType.BodyEmbedSlice &&
            (!bodyItem.embedUrl.length || !testYtURL(bodyItem.embedUrl)))
        ) {
          errorInBody = true
        }
      })
    }
    if (errorInBody) {
      setBodyError(true)
    } else {
      setBodyError(false)
    }

    let errorInPrizes = false
    if (currentItem.prizes) {
      currentItem.prizes.forEach((bodyItem) => {
        if (
          bodyItem.sliceType === BodySliceType.BodyImageSlice &&
          (!bodyItem.imgUrl.length ||
            !isValidURL(bodyItem.imgUrl) ||
            !bodyItem.imgCaptionTitle ||
            !bodyItem.imgCaption)
        ) {
          errorInPrizes = true
        }
      })
    }
    if (errorInPrizes) {
      setPrizesError(true)
    } else {
      setPrizesError(false)
    }
  }, [currentItem])

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" style={{ height: 28 }}>
          <Typography variant="h6" className="card-title">
            Details
          </Typography>
          <Stack
            alignItems="center"
            justifyContent="flex-end"
            style={{ width: "100%" }}
            direction="row"
            spacing={2}
          >
            <FormGroup style={{ marginRight: -16 }}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editMode}
                    checked={currentItem.isDefault}
                    onChange={(e) => {
                      const currentChallengeCopy: Challenge =
                        deepCopy(currentChallenge)
                      currentChallengeCopy.document.items[itemIndex].isDefault =
                        e.target.checked
                      if (e.target.checked) {
                        currentChallengeCopy.document.items.forEach(
                          (item, index) => {
                            if (index !== itemIndex) {
                              item.isDefault = false
                            }
                          }
                        )
                      }
                      setCurrentChallenge(currentChallengeCopy)
                    }}
                  />
                }
                label="Default"
              />
            </FormGroup>
            <Divider orientation="vertical" flexItem />
            <Button
              size="small"
              variant="outlined"
              style={{ height: 26, fontSize: 12 }}
              onClick={() => {
                copyDetailsFromDefault(itemIndex)
              }}
              disabled={
                currentItem.isDefault ||
                !editMode ||
                !currentChallenge.document.items.filter(
                  (item) => item.isDefault
                ).length
              }
            >
              Copy from default
            </Button>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Stack spacing={2} style={{ width: "100%" }}>
            <TextField
              fullWidth
              size="small"
              label="Title"
              value={currentItem.title}
              onChange={(e) => {
                const currentChallengeCopy: Challenge =
                  deepCopy(currentChallenge)
                currentChallengeCopy.document.items[itemIndex].title =
                  e.target.value
                setCurrentChallenge(currentChallengeCopy)

                // errors check
                if (e.target.value.length < 3 || e.target.value.length > 120) {
                  setTitleError(true)
                } else {
                  setTitleError(false)
                }
              }}
              disabled={!editMode}
              error={titleError}
              helperText={
                currentItem.title.length < 3
                  ? "Minimum length is 3 characters"
                  : currentItem.title.length > 120
                  ? "Maximum length is 60 characters"
                  : ""
              }
              onBlur={() => {
                if (currentChallenge.document.items[itemIndex].title) {
                  const currentChallengeCopy: Challenge =
                    deepCopy(currentChallenge)
                  currentChallengeCopy.document.items[itemIndex].title =
                    currentChallengeCopy.document.items[itemIndex].title.trim()
                  setCurrentChallenge(currentChallengeCopy)
                }
              }}
            />
            <TextField
              fullWidth
              size="small"
              label="Subtitle"
              value={currentItem.subtitle ?? ""}
              onChange={(e) => {
                const currentChallengeCopy: Challenge =
                  deepCopy(currentChallenge)
                currentChallengeCopy.document.items[itemIndex].subtitle = e
                  .target.value.length
                  ? e.target.value
                  : null
                setCurrentChallenge(currentChallengeCopy)
              }}
              disabled={!editMode}
              onBlur={() => {
                if (currentChallenge.document.items[itemIndex].subtitle) {
                  const currentChallengeCopy: Challenge =
                    deepCopy(currentChallenge)
                  currentChallengeCopy.document.items[itemIndex].subtitle =
                    currentChallengeCopy.document.items[
                      itemIndex
                    ].subtitle.trim()
                  setCurrentChallenge(currentChallengeCopy)
                }
              }}
            />
            <TextField
              fullWidth
              size="small"
              label="Collective prize"
              multiline
              value={currentItem.sponsor ?? ""}
              onChange={(e) => {
                const currentChallengeCopy: Challenge =
                  deepCopy(currentChallenge)
                currentChallengeCopy.document.items[itemIndex].sponsor = e
                  .target.value.length
                  ? e.target.value
                  : null
                setCurrentChallenge(currentChallengeCopy)
              }}
              disabled={!editMode}
              onBlur={() => {
                if (currentChallenge.document.items[itemIndex].sponsor) {
                  const currentChallengeCopy: Challenge =
                    deepCopy(currentChallenge)
                  currentChallengeCopy.document.items[itemIndex].sponsor =
                    currentChallengeCopy.document.items[
                      itemIndex
                    ].sponsor.trim()
                  setCurrentChallenge(currentChallengeCopy)
                }
              }}
            />
          </Stack>
          <ImagePicker
            title="Image"
            pickedImage={currentItem.image ?? ""}
            setPickedImage={(selectedImage: {
              url: string
              id: string
              description?: string
            }) => {
              const currentChallengeCopy: Challenge = deepCopy(currentChallenge)
              currentChallengeCopy.document.items[itemIndex].image =
                selectedImage.url
              setCurrentChallenge(currentChallengeCopy)
            }}
            height="100%"
            disabled={!editMode}
            width="100%"
            filters={{ category: "challenge" }}
            disableCategoryFilter
          />
        </Stack>
        <Stack spacing={2}>
          <Stack direction="row" style={{ height: 28 }}>
            <Typography variant="h6" className="card-title">
              Body
            </Typography>
            <Stack
              alignItems="center"
              justifyContent="flex-end"
              style={{ width: "100%" }}
              direction="row"
              spacing={2}
            >
              <Button
                size="small"
                variant="outlined"
                style={{ height: 26, fontSize: 12 }}
                onClick={() => {
                  copyBodyFromDefault(itemIndex)
                }}
                disabled={
                  currentItem.isDefault ||
                  !editMode ||
                  !currentChallenge.document.items.filter(
                    (item) => item.isDefault
                  ).length
                }
              >
                Copy from default
              </Button>
            </Stack>
          </Stack>
          <BlockEditor
            body={currentItem.body ?? []}
            setBody={(
              body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
            ) => {
              const currentChallengeCopy: Challenge = deepCopy(currentChallenge)
              currentChallengeCopy.document.items[itemIndex].body = body
              setCurrentChallenge(currentChallengeCopy)
            }}
            editMode={editMode}
          />
        </Stack>
        <Stack spacing={2}>
          <Stack direction="row" style={{ height: 28 }} gap={1}>
            <Typography variant="h6" className="card-title">
              Prizes
            </Typography>
            <Tooltip title="If the challenge includes prizes, they must be added here">
              <InfoRounded
                style={{
                  fontSize: 14,
                  color: "#747474",
                  marginTop: 6,
                  cursor: "pointer",
                  transition: "100ms",
                }}
              />
            </Tooltip>
            <Stack
              alignItems="center"
              justifyContent="flex-end"
              style={{ width: "100%" }}
              direction="row"
              spacing={2}
            >
              <Button
                size="small"
                variant="outlined"
                style={{ height: 26, fontSize: 12 }}
                onClick={() => {
                  copyPrizesFromDefault(itemIndex)
                }}
                disabled={
                  currentItem.isDefault ||
                  !editMode ||
                  !currentChallenge.document.items.filter(
                    (item) => item.isDefault
                  ).length
                }
              >
                Copy from default
              </Button>
            </Stack>
          </Stack>
          <BlockEditorPrizes
            prizes={currentItem.prizes ?? []}
            setPrizes={(prizes: ImageSlice[]) => {
              const currentChallengeCopy: Challenge = deepCopy(currentChallenge)
              if (prizes.length) {
                currentChallengeCopy.document.items[itemIndex].prizes = prizes
              } else {
                currentChallengeCopy.document.items[itemIndex].prizes = null
              }
              setCurrentChallenge(currentChallengeCopy)
            }}
            editMode={editMode}
          />
        </Stack>
      </Stack>
    </CardContent>
  )
}

export default ChallengeTranslationsEdit
